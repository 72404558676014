//@include font-face('GTWalsheim', '../fonts/subset-GTWalsheimProLight', $weight-light);
// @include font-face('GTWalsheim', '../fonts/subset-GTWalsheimProRegular', $weight-regular);
// @include font-face('GTWalsheim', '../fonts/subset-GTWalsheimProMedium', $weight-medium);
// @include font-face('GTWalsheim', '../fonts/subset-GTWalsheimProBold', $weight-bold);

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;600;700&display=swap');

@mixin font-main{ font-family: 'Quicksand', Arial, Helvetica, sans-serif; }

body{
	@include font-main;
	@include font-regular;
	color: $color-text;
	font-size: 1.6rem;
	background-color: $color-bg;
	letter-spacing: -.015em;
	line-height: 1.2em;
	opacity: 1;
	margin: 0;
	padding: 0;
	overflow-x: hidden;

	&.block-overflow {
		overflow-y: hidden;
	}
}

html{
	position: relative;
	font-size: 10px;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;

	@include media {
		@include calcFontSize(375);
	}

	// @include media {
	// 	@include calcFontSize(375);
	// }
}

.wrapper {
	position: relative;
	margin: 0 auto;
	width: 123rem;
	max-width: 100%;

	@include media {
		padding: 3rem;
	}
}

	.wrapper-innerwrap {
		margin-left: 24rem;
		position: relative;

		@include media {
			margin: auto;
		}
	}

.link {
	text-decoration: underline;
}