.page.content {
	//padding-top: calc(50vh - 7.3rem);
	padding-top: calc(50vh - 18.4rem);
	padding-bottom: 6rem;
	position: relative;
	left: 0;
	top: 0;

	@include media {
		padding: 6rem 0;
	}

	&.show {
		.content-title {
			opacity: 1;
			transform: translate3d(0, 0, 0);
			transition-delay: 0ms;
			transition-duration: $transition-anim;
			
		}

		.content-text {
			opacity: 1;
			transform: translate3d(0, 0, 0);
			transition-delay: 150ms;
			transition-duration: $transition-anim;			
		}
	}

	.content-innerwrap {
		position: relative;
	}

	.content-title {
		font-size: 4rem;
		margin-bottom: 4rem;
		opacity: 0;
		transition-delay: 150ms;
		transform: translate3d(-.4rem, 0, 0);
		transition:
			transform $transition-fast ease,
			opacity $transition-fast ease;
	}

	.content-text {
		opacity: 0;
		transition-delay: 0ms;
		transform: translate3d(-.4rem, 0, 0);
		transition:
			transform $transition-fast ease,
			opacity $transition-fast ease;
	}
}