.page.home {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition-delay: 250ms;
	transform: translate3d(0, -1rem, 0);
	transition:
		opacity $transition-anim ease,
		transform $transition-anim ease;

	@include media {
		padding: 3rem;
	}

	.home-logo {
		width: 35rem;
	}

	.home-menu {
		margin-top: 2rem;
		display: flex;
		justify-content: space-between;
		font-size: 3rem;
		opacity: 0;
		transform: translate3d(0, -1rem, 0);
		transition:
			opacity $transition-anim ease,
			transform $transition-anim ease;
	}

	.home-social {
		text-align: center;
		margin-top: 2.4rem;
		opacity: 0;
		transform: translate3d(0, -1rem, 0);
		transition:
			opacity $transition-anim ease,
			transform $transition-anim ease;

		.links-link {
			width: 4rem;
        	height: 4rem;
			font-size: 1.6rem;

			+ .links-link {
				margin-left: 2rem;
			}
		}
	}

	&.show {
		opacity: 1;
		transition-delay: 0;
		transform: translate3d(0, 0, 0);

		.home-menu {
			transition-delay: 250ms;
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}

		.home-social {
			transition-delay: 500ms;
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}
}