.social-links {
    .links-link {
        background-color: $color-text;
        color: $color-white;
        border-radius: 50%;
        width: 3.2rem;
        height: 3.2rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1.4rem;

        + .links-link {
            margin-left: .7rem;
        }
    }
}