.section.navigation {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: calc(50vw - 42rem);
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	// text-align: right;
	opacity: 0;
	transform: translate3d(-1rem, 0, 0);
	transition:
		opacity $transition-anim ease,
		transform $transition-anim ease;

	// @include media(1280) {
	// 	width: 24.5rem;
	// }

	@include media {
		position: relative;
		width: auto;
		text-align: center;
	}

	&.show {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	.navigation-innerwrap {
		@include mediaMin {
			text-align: right;
		}
	}

	.navigation-logo {
		width: 20rem;
		margin-left: auto;

		@include media {
			width: 35rem;
			margin: 0 auto;
		}
	}

	.navigation-menu {
		margin-top: 3rem;

		@include media {
			display: flex;
			justify-content: center;
		}
	}

		.menu-item {
			font-size: 2rem;

			+ .menu-item {
				margin-top: .5rem;

				@include media {
					margin-left: 3rem;
					margin-top: 0;
				}
			}
		}

			.item-link {
				&.active {
					@include font-bold;
				}
			}

	.navigation-social {
		margin-top: 2rem;
	}
}