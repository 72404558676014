.page.works {
	.works-list {
		list-style: none;
		display: flex;
		flex-flow: row wrap;
		margin: -1rem;
	}

		.list-item {
			width: calc(100% / 3);
			padding: 1rem;
			opacity: 0;
			transition-delay: 0ms;
			transform: translate3d(-.4rem, 0, 0);
			transition:
				transform $transition-fast ease,
				opacity $transition-fast ease;

			@include media {
				width: 100%;
			}

			&:before {
				@include pseudo;
				position: absolute;
				top: 1rem;
				left: 1rem;
				bottom: 1rem;
				right: 1rem;
				border-radius: $radius-general;
				box-shadow: $shadow-general-dark;
				opacity: 0;
				//background-color: red;
				pointer-events: none;
				z-index: 3;
				transition: opacity $transition-fast ease;
			}

			&:hover {
				&:before {
					opacity: 1;
				}

				.item-preview {
					&:before {
						transition-delay: 50ms;
						opacity: .2;
					}
				}

					.preview-logo {
						opacity: .1;
						transform: scale(1.1);
					}

					.preview-image {
						.imagewrap-image {
							background-position: bottom center;
							transition-duration: 8s;
						}
					}

				.item-text {
					&:before {
						opacity: 1;
					}
				}

			}
		}

			.item-link {
				position: relative;
				background-color: $color-gray;
				display: block;
				border-radius: $radius-general;
				box-shadow: $shadow-general;
				overflow: hidden;
			}

			.item-preview {
				position: relative;
				padding-bottom: 80%;
				overflow: hidden;
				border-radius: $radius-general;

				&:before {
					@include pseudo;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: $color-gray-dark;
					z-index: 2;
					opacity: .9;
					transition: opacity $transition-anim ease;
				}
			}

				.preview-logo {
					$logoWidth: 13rem;
					$logoHeight: 6rem;
					position: absolute;
					width: $logoWidth;
					height: $logoHeight;
					left: 50%;
					top: 50%;
					margin-left: (math.div($logoWidth,2) * -1);
					margin-top: ((math.div($logoHeight,2) + 1) * -1);
					z-index: 3;
					transition:
						opacity $transition-anim ease,
						transform $transition-anim ease;
				}

				.preview-image {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;

					.imagewrap-image {
						background-position: top center;
						transition: background-position 3s linear;
					}
				}

			.item-text {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				padding: 5rem 2rem 2rem;
				z-index: 2;

				&:before {
					@include pseudo;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					top: 0;
					opacity: 0;
					transition: opacity $transition-fast ease;
					z-index: 1;

					background: -moz-linear-gradient(top,  rgba(243,243,243,0) 0%, rgba(243,243,243,0.9) 41%, rgba(243,243,243,0.9) 100%);
					background: -webkit-linear-gradient(top,  rgba(243,243,243,0) 0%,rgba(243,243,243,0.9) 41%,rgba(243,243,243,0.9) 100%);
					background: linear-gradient(to bottom,  rgba(243,243,243,0) 0%,rgba(243,243,243,0.9) 41%,rgba(243,243,243,0.9) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f3f3f3', endColorstr='#e6f3f3f3',GradientType=0 );

				}
			}

				.text-title {
					@include font-regular;
					position: relative;
					z-index: 2;
				}

				.text-type {
					font-size: 1.2rem;
					position: relative;
					z-index: 2;
				}

	&.show {
		.list-item {
			opacity: 1;
			transform: translate3d(0, 0, 0);
			transition-duration: $transition-anim;

			@for $i from 1 through 30 {
				&:nth-child(#{$i}) {
					transition-delay: (25 + ($i*25)) * 1ms;
				}
			}
		}
	}
}