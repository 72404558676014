.wysiwyg {
	@include font-regular;

	> * {
		margin: 1em 0 .3em;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	h1, h2, h3, h4, h5, h6 {
		@include font-black;
		margin-top: 1.5em;
		margin-bottom: .8em;

		+ * {
			margin-top: 0em;
		}
	}

	i {
		font-style: italic;
	}

	strong, b {
		color: $color-text;
	}

	ul:not(.no-list) {
		list-style: disc inside;

		li {
			+ li {
				margin-top: .5em;
			}
		}
	}

	blockquote {
		font-family: inherit;
		color: $color-text;
		font-size: 1.45em;
		line-height: 1.44em;
		position: relative;

		@include media {
			padding-left: 3.9rem;
			padding-right: 3.9rem;
		}

		&:before, &:after {
			position: absolute;
			color: $color-text;
			font-size: 6em;
			opacity: .05;

			@include media {
				font-size: 9rem;
			}
		}

		&:before {
			content: '“';
			top: .25em;
			right: calc(100% + .1em);

			@include media {
				left: 0;
				right: auto;
			}
		}

		&:after {
			content: '“';
			bottom: .25em;
			left: calc(100% + 0em);
			transform: rotate(180deg);

			@include media {
				right: 0;
				left: auto;
			}
		}
	}

	.text-small {
		font-size: .8em;

		@include media {
			font-size: .5em;
		}
	}

	table {
		width: 100%;
		border: .1rem solid $color-gray;
		border-collapse: collapse;
		font-size: .8em;

		td {
			border: .1rem solid $color-gray;
			padding: .5rem .5rem
		}
	}

	a, button {
		& ,* {
			color: $color-primary;
			text-decoration: underline;
			
			@include media {
				&:active {
					color: darken($color-primary, $darken-ratio)
				}
			}

			@include mediaMin {
				&:hover, &:active {
					color: darken($color-primary, $darken-ratio)
				}	
			}
		}
	}
}